import React from 'react'
import { Flex } from '@chakra-ui/core'
import Card from './Card'

interface CarouselProps {
  data?: ItemProps[]
}

interface ItemProps {
  title: string
  image: string
  price: string
  barcode?: string
}

const Carousel = ({ data }: CarouselProps) => {
  return (
    <Flex className="carousel" p={3} pt={0} overflowX="auto" flexWrap="nowrap" style={{ direction: 'ltr' }}>
      {data &&
        data.map((item: ItemProps, idx: number) => (
          <Card key={idx} idx={idx} title={item.title} image={item.image} price={item.price} barcode={item.barcode} />
        ))}
    </Flex>
  )
}

export default Carousel
