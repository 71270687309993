import React from 'react'
import { HeaderProps } from './../interfaces'
import closeIcon from '../../../../../assets/close-story.svg'
import styled from 'styled-components'

const BadgeNumber = styled.div`
  background-color: #f6f6f6;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 14px;
`

const Header = ({ profileImage, closeStory, id }: HeaderProps) => (
  <div style={styles.main} className="header">
    {profileImage && <img style={styles.img} src={profileImage} alt="" />}
    <BadgeNumber style={styles.heading}>{id + 1}</BadgeNumber>
    <span style={styles.text} />
    <div
      style={{ position: 'relative', zIndex: 99999, cursor: 'pointer' }}
      className="close-button"
      onClick={() => {
        console.log('clicked close')
        closeStory()
      }}
    >
      <img src={closeIcon} alt="" />
    </div>
  </div>
)

const styles = {
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 12,
    paddingRight: 12,
  } as React.CSSProperties,
  img: {
    width: 40,
    height: 40,
    borderRadius: 40,
    marginRight: 10,
    filter: 'drop-shadow(0 0px 2px rgba(0, 0, 0, 0.5))',
    border: '2px solid rgba(255, 255, 255, 0.8)',
  } as React.CSSProperties,
  text: {
    display: 'flex',
    flexDirection: 'column',
    filter: 'drop-shadow(0 0px 3px rgba(0, 0, 0, 0.4))',
  } as React.CSSProperties,
  heading: {
    fontSize: '1rem',
    color: '#000',
    margin: 0,
    marginBottom: 2,
  } as React.CSSProperties,
  subheading: {
    fontSize: '0.6rem',
    color: '#000',
    margin: 0,
  } as React.CSSProperties,
}

export default Header
