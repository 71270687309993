import axios from 'axios'
import { getParameterByName, getProductsByDefaultDepartment, get } from '../../utils'
import config from '../../config/config'
import dataConfig from '../../config/dataConfig'

export const actionTypes = {
  SET_CONFIG: 'SET_CONFIG',
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_RAW_PRODUCTS: 'SET_RAW_PRODUCTS',
  TOGGLE_STORIES: 'TOGGLE_STORIES',
}

export const setConfig = (config: any) => ({
  type: actionTypes.SET_CONFIG,
  config,
})

export const setProducts = (products: any) => ({
  type: actionTypes.SET_PRODUCTS,
  products,
})

export const setRawProducts = (rawProducts: any) => ({
  type: actionTypes.SET_RAW_PRODUCTS,
  rawProducts,
})

export const generalHandler = () => async (dispatch: any) => {
  const id = getParameterByName('id')
  const rid = getParameterByName('rid')
  const sid = getParameterByName('sid')
  const { apiUrl } = config
  const url = `https://${apiUrl}/?id=${id}&rid=${rid}&sid=${sid}`

  try {
    const { data } = await axios.get(url)
    const defaultDepartment = get(data.data.config, 'default_department', null)
    const filteredProductsByDepartment = getProductsByDefaultDepartment(data.data.products, defaultDepartment)

    const locale = get(data.data.config, 'locale', 'he')
    const roll_type = get(data.data.config, 'roll_type', 0)

    const configData = {
      ...data.data.config,
      locale,
      roll_type,
      texts: {
        ...dataConfig.texts,
        ...data.data.config.texts,
      },
    }
    dispatch(setRawProducts(data.data.products))
    dispatch(setProducts(filteredProductsByDepartment))
    dispatch(setConfig(configData))
  } catch (error) {
    console.log(error)
  }
}

export const filterProductsByDepartment = (department: any) => (dispatch: any, getState: any) => {
  const { general } = getState()

  const section1 = general.rawProducts.section1
  const section2 = general.rawProducts.section2
  const filteredSection1Products = general.rawProducts.section1.filter((item: any) => item.department === department)
  const filteredSection2Products = general.rawProducts.section2.filter((item: any) => item.department === department)
  const products = {
    section1: department === 'all' ? section1 : filteredSection1Products,
    section2: department === 'all' ? section2 : filteredSection2Products,
  }

  dispatch(setProducts(products))
}
