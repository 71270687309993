import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import ModalWrapper from './ModalWrapper'

const duration = 300

const Drawer = ({ isOpen, onClose, children, isFull, isBottom }) => {
  const className = isBottom ? 'drawerBottom' : 'drawer'
  return (
    <CSSTransition in={isOpen} className={className} classNames={className} unmountOnExit timeout={duration}>
      <ModalWrapper title="No soup for you" open={isOpen} onClose={onClose} isFull={isFull} isBottom={isBottom}>
        {children}
      </ModalWrapper>
    </CSSTransition>
  )
}

Drawer.defaultProps = {
  isOpen: false,
  isFull: false,
  isBottom: false,
}

Drawer.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  isFull: PropTypes.bool,
  isBottom: PropTypes.bool,
  onClose: PropTypes.any,
}

export default Drawer
