/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from 'react'
import { StoryProps, GlobalCtx } from './../interfaces'
import Header from './Header'
import SeeMore from './SeeMore'
import globalStyle from './../styles.css'
import GlobalContext from './../context/Global'
import styled from 'styled-components'

const CardBottom = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 1700;
  width: 100%;
  padding: 40px 0 20px 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
`

const Story = (props: StoryProps) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const [showMore, setShowMore] = useState<boolean>(false)
  const [isPortrait, setIsPortrait] = useState(false)

  const { width, height, loader, header, storyStyles } = useContext<GlobalCtx>(GlobalContext)

  let vid = useRef<HTMLVideoElement>(null)

  const toggleMore = (show: boolean) => {
    setShowMore(show)
    props.action(show ? 'pause' : 'play')
  }

  const imageLoaded = ({ target }: any) => {
    try {
      const isPortrait = target.offsetHeight > target.offsetWidth
      setLoaded(true)
      setIsPortrait(isPortrait)
    } catch (e) {
      console.log(e)
    }
  }

  const getStoryContent = () => {
    let InnerContent = typeof props.story === 'object' && props.story.content
    if (InnerContent) {
      return <InnerContent action={props.action} isPaused={props.playState} />
    } else {
      let source = typeof props.story === 'object' ? props.story.url : props.story
      let storyContentStyles =
        (typeof props.story === 'object' && props.story.styles) || storyStyles || styles.storyContent
      let type = typeof props.story === 'object' && props.story.type === 'video' ? 'video' : 'image'
      return type === 'image' ? (
        <img
          style={{
            ...storyContentStyles,
            ...{ objectFit: isPortrait ? 'cover' : 'contain' },
          }}
          src={source}
          onLoad={imageLoaded}
        />
      ) : type === 'video' ? (
        <video ref={vid} style={storyContentStyles} src={source} controls={false} autoPlay playsInline />
      ) : null
    }
  }

  let isHeader = typeof props.story === 'object' && props.story.header
  return (
    <div
      className="story-wrapper"
      style={{
        ...styles.story,
        width: width,
        height: height,
        flexDirection: isPortrait ? 'row' : 'column',
      }}
    >
      {getStoryContent()}
      {isHeader && (
        <div style={{ position: 'absolute', top: 20, zIndex: 1700, width: '100%' }}>
          {typeof props.story === 'object' ? (
            header ? (
              header(props.story.header)
            ) : (
              <Header
                heading={props.story.header!.heading}
                subheading={props.story.header!.subheading}
                profileImage={props.story.header!.profileImage}
                closeStory={props.closeStory}
                id={props.id}
              />
            )
          ) : null}
        </div>
      )}

      <CardBottom className="card-bottom">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: 12,
            paddingRight: 12,
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              filter: 'drop-shadow(0 0px 3px rgba(0, 0, 0, 0.4))',
            }}
          >
            <p
              style={{
                fontSize: '18px',
                color: '#000',
                margin: 0,
                marginBottom: 2,
              }}
            >
              {props.story.header!.heading}
            </p>
            <p
              style={{
                fontSize: '16px',
                color: '#000',
                margin: 0,
              }}
            >
              {props.story.header!.subheading}
            </p>
          </span>
        </div>
      </CardBottom>
      {!loaded && (
        <div
          className="overlay"
          style={{
            width: width,
            height: height,
            position: 'absolute',
            left: 0,
            top: 0,
            background: 'rgba(0, 0, 0, 0.9)',
            zIndex: 9,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#ccc',
          }}
        >
          {loader || <div className={globalStyle.spinner} />}
        </div>
      )}
      {typeof props.story === 'object' && props.story.seeMore && (
        <div
          style={{
            position: 'absolute',
            margin: 'auto',
            bottom: showMore ? 'unset' : 0,
            zIndex: 1600,
            width: '100%',
            height: showMore ? '100%' : 'auto',
          }}
        >
          <SeeMore
            action={props.action}
            toggleMore={toggleMore}
            showContent={showMore}
            seeMoreContent={props.story.seeMore}
          />
        </div>
      )}
    </div>
  )
}

const styles = {
  story: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
  } as React.CSSProperties,
  storyContent: {
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    zIndex: 10,
  } as React.CSSProperties,
}

export default Story
