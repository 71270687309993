import React from 'react'
import { connect } from 'react-redux'
import translations from '../../data/translations'
import { isSolid } from '../../utils'

interface LocaleTextProps {
  text: any
  locale: any
}

const LocaleText = ({ text, locale }: LocaleTextProps) => {
  const getTranslation: any = { ...translations }
  const translatedText = getTranslation[locale][text]
  if (isSolid(translatedText)) {
    return <span>{isSolid(text) && translatedText}</span>
  } else {
    return <span>{isSolid(text) && text}</span>
  }

}

const mapStateToProps = ({ general }: any) => ({
  locale: general.config.locale,
})

export default connect(mapStateToProps)(LocaleText)
