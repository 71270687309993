/* eslint-disable */
import React, { useContext, useState, useRef, useEffect } from 'react'
import GlobalContext from './../context/Global'
import ProgressContext from './../context/Progress'
import Story from './Story'
import ProgressArray from './ProgressArray'
import { GlobalCtx } from './../interfaces'
import styled from 'styled-components'

const TopGradient = styled.div`
  width: 100%;
  height: 8vh;
  position: absolute;
  z-index: 98;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
`

export default function() {
  const [currentId, setCurrentId] = useState<number>(0)
  const [pause, setPause] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [bufferAction, setBufferAction] = useState<boolean>(true)
  const [videoDuration, setVideoDuration] = useState<number>(0)

  let mousedownId = useRef<any>()
  let animationFrameId = useRef<number>()

  const {
    width,
    height,
    defaultInterval,
    stories,
    loop,
    currentIndex,
    isPaused,
    onStoryStart,
    onStoryEnd,
    onAllStoriesEnd,
    closeStory,
  } = useContext<GlobalCtx>(GlobalContext)

  useEffect(() => {
    if (!pause) {
      animationFrameId.current = requestAnimationFrame(incrementCount)
    }
    return () => {
      cancelAnimationFrame(animationFrameId.current!)
    }
  }, [currentId, pause])

  useEffect(() => {
    setCount(0)
  }, [currentId, stories])

  useEffect(() => {
    if (typeof currentIndex === 'number') {
      if (currentIndex >= 0 && currentIndex < stories.length) {
        setCurrentId(currentIndex)
      } else {
        console.error(
          'Index out of bounds. Current index was set to value more than the length of stories array.',
          currentIndex
        )
      }
    }
  }, [currentIndex])

  useEffect(() => {
    if (typeof isPaused === 'boolean') {
      setPause(isPaused)
    }
  }, [isPaused])

  const incrementCount = () => {
    setCount((count: number) => {
      if (count === 0) storyStartCallback()
      const interval = getCurrentInterval()
      if (count < 100) {
        animationFrameId.current = requestAnimationFrame(incrementCount)
      } else {
        storyEndCallback()
        if (currentId === stories.length - 1) {
          allStoriesEndCallback()
        }
        next()
      }
      return count + 100 / ((interval! / 1000) * 60)
    })
  }

  const storyStartCallback = () => {
    onStoryStart && onStoryStart(currentId, stories[currentId])
  }

  const storyEndCallback = () => {
    onStoryEnd && onStoryEnd(currentId, stories[currentId])
  }

  const allStoriesEndCallback = () => {
    onAllStoriesEnd && onAllStoriesEnd()
  }

  const getCurrentInterval = () => {
    if (stories[currentId].type === 'video') return videoDuration
    if (typeof stories[currentId].duration === 'number') return stories[currentId].duration
    return defaultInterval
  }

  const toggleState = (action: string, bufferAction?: boolean) => {
    setPause(action === 'pause')
    setBufferAction(!!bufferAction)
  }

  const previous = () => {
    if (currentId > 0) {
      setCurrentId(currentId - 1)
      setCount(0)
    }
  }

  const next = () => {
    if (loop) {
      updateNextStoryIdForLoop()
    } else {
      updateNextStoryId()
    }
  }

  const updateNextStoryIdForLoop = () => {
    setCurrentId((currentId + 1) % stories.length)
    setCount(0)
  }

  const updateNextStoryId = () => {
    if (currentId < stories.length - 1) {
      setCurrentId(currentId + 1)
      setCount(0)
    }
  }

  const debouncePause = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault()
    mousedownId.current = setTimeout(() => {
      toggleState('pause')
    }, 200)
  }

  const mouseUp = (e: React.MouseEvent | React.TouchEvent, type: string) => {
    e.preventDefault()
    mousedownId.current && clearTimeout(mousedownId.current)
    type === 'next' ? next() : previous()
  }

  const getVideoDuration = (duration: number) => {
    setVideoDuration(duration * 1000)
  }

  return (
    <div style={{ ...styles.container, ...{ width, height } }}>
      <TopGradient className="top-gradient" />
      <ProgressContext.Provider
        value={{
          bufferAction: bufferAction,
          videoDuration: videoDuration,
          numArray: stories.map((_, i) => i),
          currentStory: stories[currentId],
          currentId,
          count,
          pause,
        }}
      >
        <ProgressArray />
      </ProgressContext.Provider>
      <Story
        action={toggleState}
        bufferAction={bufferAction}
        playState={pause}
        story={stories[currentId]}
        getVideoDuration={getVideoDuration}
        closeStory={closeStory}
        id={currentId}
      />
      <div style={styles.overlay}>
        <div
          style={{ width: '50%', zIndex: 999 }}
          onTouchStart={debouncePause}
          onTouchEnd={e => mouseUp(e, 'previous')}
          onMouseDown={debouncePause}
          onMouseUp={e => mouseUp(e, 'previous')}
        />
        <div
          style={{ width: '50%', zIndex: 999 }}
          onTouchStart={debouncePause}
          onTouchEnd={e => mouseUp(e, 'next')}
          onMouseDown={debouncePause}
          onMouseUp={e => mouseUp(e, 'next')}
        />
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    position: 'relative',
  } as React.CSSProperties,
  overlay: {
    position: 'absolute',
    height: 'inherit',
    width: 'inherit',
    display: 'flex',
  } as React.CSSProperties,
}
