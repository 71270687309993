import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Box, Flex, AspectRatioBox, Icon, Link, useDisclosure } from '@chakra-ui/core'
import { ProductProps } from '../../config/interfaces'
import CardTop from './CardTop'
import CardBottom from './CardBottom'
import CardBadge from './CardBadge'
import { Image, BarcodeDrawer, Drawer } from '../../components'
import { isSolid, get } from '../../utils'
import config from '../../config/config'

interface CardProps extends ProductProps {
  rid: string
  index: number
}
const Card = ({
  image,
  title,
  description,
  price,
  badge,
  likes,
  colors,
  id,
  barcode,
  type,
  link,
  className,
  associateText,
  rid,
  index,
}: CardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [barcodeFromRemote, setBarcodeFromRemote] = useState('')

  const getBarcode = async () => {
    const barcodeFromLocalStorage = localStorage.getItem('barcode')
    if (barcodeFromLocalStorage !== null) {
      setBarcodeFromRemote(barcodeFromLocalStorage)
      return
    }
    const { apiUrl } = config
    const url = `https://${apiUrl}/barcode?id=17&retail_id=laline&sid=1&bucket_id=${index + 1}`
    try {
      const { data } = await axios.get(url)
      setBarcodeFromRemote(data.barcode)
      localStorage.setItem('barcode', data.barcode)
    } catch (err) {
      console.log(err)
    }
  }

  const onCardClick = () => {
    console.log('on card click')
    if (rid === 'laline') {
      getBarcode()
    }

    if (onOpen) {
      onOpen()
    }
  }
  return (
    <Box id={id} width="100%" bg="white" overflow="hidden" position="relative" mb={3}>
      <CardTop className={className} title={title} price={price} />
      {isSolid(badge) && <CardBadge badge={badge} className={className} />}

      {type === 'external' ? (
        <Link href={link} isExternal className={className} cursor="pointer">
          <Image className={className} src={image!} />
        </Link>
      ) : (
        <Box className={className} onClick={onCardClick} cursor="pointer">
          <Image className={className} src={image!} />
        </Box>
      )}

      <CardBottom className={className} colors={colors} likes={likes} />

      {type === 'iframe' ? (
        <Drawer isOpen={isOpen} onClose={onClose} isFull>
          <Box bg="gray.500" overflow="auto" display="block" height="100%" position="relative">
            <Flex
              className="drawer-header ltr"
              bg="brand"
              color="white"
              py="20px"
              height={62}
              zIndex={1100}
              alignItems="center"
            >
              <Box as="button" color="black" cursor="pointer" onClick={onClose} mx="10px">
                <Icon name="close" size="18px" />
              </Box>
            </Flex>
            <Box px={0} pt={3}>
              <AspectRatioBox maxW="560px" ratio={1}>
                <iframe
                  title="post link"
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0px',
                    left: '0px',
                  }}
                  src={link}
                />
              </AspectRatioBox>
            </Box>
          </Box>
        </Drawer>
      ) : (
        <BarcodeDrawer
          title={title}
          description={description}
          price={price}
          image={image}
          isOpen={isOpen}
          onClose={onClose}
          barcode={isSolid(barcodeFromRemote) ? barcodeFromRemote : barcode}
          associateText={associateText}
        />
      )}
    </Box>
  )
}

const mapStateToProps = ({ general }: any) => ({
  rid: general.config.rid,
})

export default connect(mapStateToProps)(Card)
