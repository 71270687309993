import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box } from '@chakra-ui/core'
import Stories from '../react-insta-stories/src/index'
import { generateStoriesFromProducts } from '../../../utils'

const InstaStories = ({ storyIndex, items, closeStory, showByDepartment, storyDepartment }) => {
  const filteredProductsByDepartment = items.filter(item => item.department === storyDepartment)
  const stories = showByDepartment
    ? generateStoriesFromProducts(filteredProductsByDepartment)
    : generateStoriesFromProducts(items)
  return (
    <Box style={{ direction: 'ltr' }}>
      <Stories
        stories={stories}
        defaultInterval={1500}
        width={window.innerWidth}
        height={window.innerHeight}
        isPaused
        currentIndex={showByDepartment ? 0 : storyIndex}
        closeStory={closeStory}
      />
    </Box>
  )
}

InstaStories.defaultProps = {
  storyIndex: 0,
}

InstaStories.propTypes = {
  storyIndex: PropTypes.number,
  items: PropTypes.array,
  showByDepartment: PropTypes.bool,
  storyDepartment: PropTypes.string,
  closeStory: PropTypes.func,
}

const mapStateToProps = ({ general }) => ({
  items: general.config.roll_type === 0 ? general.products.section1 : general.rawProducts.section1,
})

export default connect(mapStateToProps)(InstaStories)
