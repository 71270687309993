import React from 'react'
import { connect } from 'react-redux'
import { Box, Flex, Text, Image } from '@chakra-ui/core'
import { Carousel, StoryCarousel } from '../../components'
import { ProductProps } from '../../config/interfaces'
import { CardLogoLoader } from '../Loaders'
import { isSolid } from '../../utils'

interface BestSellersProps extends ProductProps {
  products?: any[]
  logo?: any
  departments?: any[]
}

const BestSellers = ({ title, logo, products, rollType, departments }: BestSellersProps) => {
  return (
    <>
      {isSolid(products) && (
        <Box className="best-sellers" bg="white">
          {rollType === 0 && (
            <Flex className="best-sellers-header" justifyContent="space-between">
              <Flex>
                <Flex width="64px" height="64px" p={3} flexDirection="column" justifyContent="center">
                  {isSolid(logo) ? <Image src={logo} borderRadius="50%" /> : <CardLogoLoader />}
                </Flex>
                <Box py={3}>
                  <Text color="black" fontSize={14} fontWeight={500}>
                    {title}
                  </Text>
                </Box>
              </Flex>
            </Flex>
          )}

          {rollType === 0 ? <Carousel data={products} /> : <StoryCarousel data={departments} />}
        </Box>
      )}
    </>
  )
}

BestSellers.defaultProps = {
  title: 'The Best Sellers',
  subtitle: 'For 30 days',
  rollType: 0,
}

const mapStateToProps = ({ general }: any) => ({
  products: general.products.section1,
  logo: general.config.avatar,
  rollType: general.config.roll_type,
  departments: general.config.departments,
})

export default connect(mapStateToProps)(BestSellers)
