import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import ModalWrapper from './ModalWrapper'

const duration = 300

const Modal = ({ isOpen, onClose, children, isFull, ModalContentBg }) => {
  return (
    <CSSTransition in={isOpen} className="alert" classNames="alert" unmountOnExit timeout={duration}>
      <ModalWrapper
        title="No soup for you"
        open={isOpen}
        onClose={onClose}
        isFull={isFull}
        ModalContentBg={ModalContentBg}
      >
        {children}
      </ModalWrapper>
    </CSSTransition>
  )
}

Modal.defaultProps = {
  isOpen: false,
  isFull: false,
}

Modal.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  isFull: PropTypes.bool,
  onClose: PropTypes.any,
  ModalContentBg: PropTypes.string,
}

export default Modal
