import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Box, Flex, Heading, Text, Button } from '@chakra-ui/core'
import { Modal, LocaleText } from '../../components'
import styled from 'styled-components'
import LikeIcon from '../../assets/like.svg'
import { isSolid, get } from '../../utils'

const StyledLike = styled(Box)`
  position: absolute;
  top: -36px;
  background: white;
  width: 73px;
  height: 73px;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #dadde1;
`
interface NotifyProps {
  show?: boolean
  products?: {}
  config?: {}
  pop?: any
}

const Notify = ({ products, pop }: NotifyProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isFirstTimeRendered, setIsFirstTimeRendered] = useState(false)
  const [header, setHeader] = useState('notification_header')
  const [p, setP] = useState('notification_description')
  const [btn, setBtn] = useState('notification_button')

  useEffect(() => {
    if (isSolid(products) && !isFirstTimeRendered) {
      setIsFirstTimeRendered(true)
      setIsOpen(true)
    }
    if (isSolid(pop)) {
      if (!isSolid(pop.header) || !isSolid(pop.p) || !isSolid(pop.btn)) {
        throw new Error('Something went wrong with one of pop properties')
      } else {
        setHeader(pop.header)
        setP(pop.p)
        setBtn(pop.btn)
      }
    }
  })

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <StyledLike>
        <img src={LikeIcon} alt="like" />
      </StyledLike>

      <Box>
        <Heading as="h3" fontSize={18} textAlign="center" lineHeight="28px" mt="30px" mb="10px">
          <LocaleText text={header} />
        </Heading>
        <Text fontSize="14px" color="gray.700" textAlign="center" mb="25px">
          <LocaleText text={p} />
        </Text>
        <Flex justifyContent="center">
          <Button
            className="great"
            onClick={() => setIsOpen(false)}
            width={160}
            bg="green"
            color="white"
            fontWeight="bold"
            borderRadius="20px"
          >
            <LocaleText text={btn} />
          </Button>
        </Flex>
      </Box>
    </Modal>
  )
}

const mapStateToProps = ({ general }: any) => ({
  locale: general.config.locale,
  products: general.products.section2,
  pop: general.config.pop,
})

export default connect(mapStateToProps)(Notify)
