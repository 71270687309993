import React, { useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { Box } from '@chakra-ui/core'
import PropTypes from 'prop-types'

const ModalCoontainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
`

const ModalOverlay = styled(Box)`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1300;
`
const ModalContent = styled(Box)`
  position: fixed;
  z-index: 1400;
  pointer-events: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 14px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px;
  will-change: opacity, transform;
  transform: translateX(0%);
  right: 0px;
  top: 0px;
  height: 100vh;
  outline: 0px;
  overflow-y: auto;
`

const ModalContentBottom = styled(Box)`
  outline: 0;
  max-width: 100vw;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 1400;
  background-color: #fff;
  box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  will-change: opacity, transform;
  transform: translateY(0%);
  height: auto;
  min-height: 20vh;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 12px 12px 0 0;
`

const ModalWrapper = ({ showCloseIcon, onClose, isFull, children, className, isBottom }) => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden'
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyle
    }
  }, []) // Empty array ensures effect is only run on mount and unmount

  const modalMarkup = (
    <ModalCoontainer className={className}>
      <ModalOverlay className="modal-overlay" onClick={onClose} />

      {isBottom ? (
        <ModalContentBottom className="modal-content isBottom" role="document">
          {children}
        </ModalContentBottom>
      ) : (
        <ModalContent className="modal-content" role="document" maxWidth={isFull ? '100%' : '20rem'}>
          {children}
        </ModalContent>
      )}
    </ModalCoontainer>
  )

  return ReactDOM.createPortal(modalMarkup, document.body)
}

ModalWrapper.propTypes = {
  /**
   * Child nodes
   */
  children: PropTypes.node.isRequired,
  /**
   * Close handler for modal button
   */
  onClose: PropTypes.func,
  /**
   * Title text to show in header
   */
  title: PropTypes.string.isRequired,
  /**
   * Whether to show a close icon
   */
  showCloseIcon: PropTypes.bool,
  /**
   * Drawer to in full width
   */
  isFull: PropTypes.bool,
  isBottom: PropTypes.bool,
}

ModalWrapper.defaultProps = {
  onClose: null,
  showCloseIcon: true,
  isBottom: false,
}

export default ModalWrapper
