import { theme } from '@chakra-ui/core'
import colors from '../constants/colors'

const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    ...colors,
  },
}

export default customTheme
