import React from 'react'
import Img from 'react-image'
import { CardImageLoader } from '../Loaders'

interface ImageProps {
  src: string
  style?: {}
  className?: string
  onLoad?: any
}

const Card = ({ src, style, className, onLoad }: ImageProps) => {
  return <Img onLoad={onLoad} style={style} className={className} src={src} loader={<CardImageLoader />} />
}

export default Card
