import { actionTypes } from '../actions/general'
import dataConfig from '../../config/dataConfig'

// TODO: add types for initialState

export const initialState: any = {
  config: dataConfig,
  products: {},
  rawProducts: {},
}

const generalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_CONFIG:
      return {
        ...state,
        config: action.config,
      }

    case actionTypes.SET_PRODUCTS:
      return {
        ...state,
        products: action.products,
      }

    case actionTypes.SET_RAW_PRODUCTS:
      return {
        ...state,
        rawProducts: action.rawProducts,
      }

    default:
      return state
  }
}

export default generalReducer
