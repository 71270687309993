export const actionTypes = {
  SET_TOKEN: 'SET_TOKEN',
}

export const setToken = (token: string) => ({
  type: actionTypes.SET_TOKEN,
  token,
})

export const loginHandler = (username: string, password: string) => async (dispatch: any) => {}
