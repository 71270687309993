import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ThemeProvider, CSSReset } from '@chakra-ui/core'
import { Helmet } from 'react-helmet'
import customTheme from './config/theme'
import Kit from './Kit'
import { generalHandler } from './redux/actions/general'
import { isSolid } from './utils'
import './App.css'

const App = (props: any) => {
  let theme = { ...customTheme }

  useEffect(() => {
    props.generalHandler()
    // eslint-disable-next-line
	}, [])

  useEffect(() => {
    if (isSolid(props.brandColor)) {
      theme.colors.brand = props.brandColor
    }
    if (isSolid(props.brandTextColor)) {
      theme.colors.brandTextColor = props.brandTextColor
    }
  })

  const isHebrew = props.locale === 'he'

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <html lang={isHebrew ? 'he' : 'en'} dir={isHebrew ? 'rtl' : 'ltr'} />
        <body className={isHebrew ? 'rtl' : 'ltr'} />
      </Helmet>
      <CSSReset />
      <Kit />
    </ThemeProvider>
  )
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = ({ general }: any) => ({
  brandColor: general.config.colors.brand_background_color,
  brandTextColor: general.config.colors.brand_text_color,
  locale: general.config.locale,
})

const dispatchPropsToState = {
  generalHandler,
}

export default connect(mapStateToProps, dispatchPropsToState)(App)
