import React from 'react'
import ContentLoader from 'react-content-loader'

export const CardImageLoader = () => (
  <ContentLoader height={500} width={400} speed={1} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="500" />
  </ContentLoader>
)

export const CardLogoLoader = () => (
  <ContentLoader height={64} width={64} speed={1} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
    <circle cx="30" cy="30" r="30" />
  </ContentLoader>
)

export const CardTitleLoader = () => (
  <ContentLoader height={60} width={220} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
    <rect x="4" y="13" rx="4" ry="4" width="100" height="13" />
    <rect x="4" y="37" rx="4" ry="4" width="50" height="8" />
  </ContentLoader>
)
