import { actionTypes } from '../actions/auth'

export const initialState: any = {
  token: '',
}

const reducerAuth = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      }

    default:
      return state
  }
}

export default reducerAuth
