export default {
  logo: '',
  avatar: '',
  departments: [],
  colors: {
    brand_background_color: '#000000',
    brand_text_color: '#ffffff',
  },
  texts: {
    post_text: 'המומלצים ביותר',
    roll_text: 'הנמכרים ביותר',
    all_departments_text: 'כל הקטגוריות',
    associate_text: 'בקש/י מהמוכר',
  },
  barcode_type: 'Code-128',
  social: { facebook: '', website: '', instagram: '' },
  locale: 'he',
}
