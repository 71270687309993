export default {
  black: '#1d2129',
  gray: {
    100: '#f6f6f6',
    500: '#dadde1',
    700: '#90949c',
    900: '#616770',
  },
  brand: '#dadde1',
  brandTextColor: '#1d2129',
  red: '#c92526',
  green: '#2DCE85',
}
