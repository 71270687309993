/* eslint-disable no-prototype-builtins */
import isNil from 'lodash/isNil'
import isPlainObject from 'lodash/isPlainObject'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
// This util function checks if the given value is undefined, null or empty string/array/object
export function isSolid(val) {
  if (isNil(val)) {
    // value is null or undefined
    return false
  } else {
    // is not null or undefined

    if (val.hasOwnProperty('length')) {
      if (val.length > 0) {
        // is STRING or ARRAY
        return true
      } else {
        // is empty STRING or ARRAY
        return false
      }
    } else if (isPlainObject(val)) {
      if (Object.keys(val).length > 0) {
        // is OBJECT
        return true
      } else {
        // is empty OBJECT
        return false
      }
    } else {
      // is NUMBER
      return true
    }
  }
}

export function getParameterByName(name, url = window.location.href) {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function generateStoriesFromProducts(section1Products) {
  return section1Products.map(product => {
    return {
      ...product,
      url: product.image,
      header: {
        heading: product.title,
        subheading: product.price,
      },
    }
  })
}

export const getProductsByDefaultDepartment = (rawProducts, defaultDepartment) => {
  const section1 = rawProducts.section1
  const section2 = rawProducts.section2
  const filteredSection1Products = rawProducts.section1.filter(item => item.department === defaultDepartment)
  const filteredSection2Products = rawProducts.section2.filter(item => item.department === defaultDepartment)
  const products = {
    section1: defaultDepartment ? filteredSection1Products : section1,
    section2: defaultDepartment ? filteredSection2Products : section2,
  }

  return products
}

export function getUniqueDepartments(products) {
  if (!isSolid(products)) return
  const departments = products.map(item => item.department)
  return uniq(departments)
}

export function setItemsPerUniqueDepartment(products, departments) {
  if (!isSolid(products)) return

  let arr = []

  departments.forEach(department => {
    const product = products.find(item => item.department === department)
    arr = [...arr, product]
  })

  return arr
}

export { get, uniq }
