import React, { useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { Box } from '@chakra-ui/core'
import PropTypes from 'prop-types'

const ModalCoontainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
`

const ModalOverlay = styled(Box)`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1300;
`
const ModalContent = styled(Box)`
  z-index: 1400;
  pointer-events: auto;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 14px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px;
  will-change: opacity, transform;
  transform: translateX(0%);
  right: 0px;
  top: 0px;
  outline: 0px;
  overflow-y: visible;
  border-radius: 6px;
  margin-left: 1rem;
  margin-right: 1rem;
`

const ModalWrapper = ({ onClose, isFull, children, className, ModalContentBg }) => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden'
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyle
    }
  }, []) // Empty array ensures effect is only run on mount and unmount

  const modalMarkup = (
    <ModalCoontainer className={className}>
      <ModalOverlay className="modal-overlay" onClick={onClose} />
      <ModalContent
        className="modal-content"
        role="document"
        maxWidth={isFull ? '100%' : '350px'}
        height={isFull ? '100%' : 'auto'}
        bg={ModalContentBg}
        mx={isFull ? '0' : '1rem'}
        p={isFull ? '0' : '30px'}
      >
        {children}
      </ModalContent>
    </ModalCoontainer>
  )

  return ReactDOM.createPortal(modalMarkup, document.body)
}

ModalWrapper.propTypes = {
  /**
   * Child nodes
   */
  children: PropTypes.node.isRequired,
  /**
   * Close handler for modal button
   */
  onClose: PropTypes.func,
  /**
   * Title text to show in header
   */
  title: PropTypes.string.isRequired,
  /**
   * Whether to show a close icon
   */
  showCloseIcon: PropTypes.bool,
  /**
   * Drawer to in full width
   */
  isFull: PropTypes.bool,
  ModalContentBg: PropTypes.string,
}

ModalWrapper.defaultProps = {
  onClose: null,
  showCloseIcon: true,
  ModalContentBg: '#EDF0F5',
}

export default ModalWrapper
