import React from 'react'
import { connect } from 'react-redux'
import { Box, Text, Flex, DrawerBody, useDisclosure } from '@chakra-ui/core'
import styled from 'styled-components'
import { useWindowSize } from '../../utils/hooks'
import { Image, Modal, InstaStories } from '../../components'
import { ProductProps } from '../../config/interfaces'
import { isSolid } from '../../utils'

const CardTitleWrap = styled(Flex)`
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CardBottom = styled(Box)``

const CardTitle = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
  font-size: 10px;
`

interface CarouselCardProps extends ProductProps {
  idx?: any
  department?: string
  avatar?: string
  locale?: string
}

const Avatar = styled(Box)`
  font-size: calc(0.4rem);
  line-height: 1rem;
  vertical-align: top;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  position: relative;
  width: 1rem;
  height: 1rem;
  color: rgb(255, 255, 255);
  border-radius: 9999px;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  border-image: initial;
  background-color: white;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 9999px;
  }
`

const Card = ({ idx, image, department, avatar, locale }: CarouselCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const size: any = useWindowSize()
  return (
    <Box
      style={{
        width: size.width / 6.3,
        margin: idx === 0 && locale === 'he' ? 0 : locale === 'he' ? '0 0 0 15px' : '0 15px 0 0',
      }}
      bg="white"
      flex="0 0 auto"
      position="relative"
      overflow="hidden"
      cursor="pointer"
      id={`roll-${idx + 1}`}
    >
      <Flex onClick={onOpen} borderRadius="50%" border="2px solid tomato" p="2px">
        <Flex
          className={`roll-${idx + 1} image-wrapper`}
          overflow="hidden"
          position="relative"
          borderRadius="50%"
          style={{ height: '14.5vw' }}
        >
          <Image
            className={`roll-${idx + 1}`}
            style={{
              width: '100%',
              objectFit: 'cover',
            }}
            src={image!}
          />
        </Flex>
      </Flex>

      {idx === 0 && (
        <Box position="absolute" right="4px" bottom="8px">
          <Avatar className="avatar">
            <img src={avatar} alt="avatar" />
          </Avatar>
        </Box>
      )}
      <CardBottom className={`card-bottom roll-${idx + 1}`} width="100%">
        {isSolid(department) && (
          <CardTitleWrap className={`roll-${idx + 1}`} color="black" textAlign="center" fontSize={12} fontWeight={500}>
            <CardTitle>{department}</CardTitle>
          </CardTitleWrap>
        )}
      </CardBottom>

      <Modal isOpen={isOpen} onClose={onClose} isFull ModalContentBg="#111">
        <DrawerBody color="white" p={0}>
          <InstaStories closeStory={onClose} storyIndex={idx} showByDepartment storyDepartment={department} />
        </DrawerBody>
      </Modal>
    </Box>
  )
}

const mapStateToProps = ({ general }: any) => ({
  avatar: general.config.avatar,
  locale: general.config.locale,
})

export default connect(mapStateToProps)(Card)
