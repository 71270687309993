const translations = {
  he: {
    notification_header: 'תודה שבחרת במערכת ההמלצות הדיגיטלית שלנו!',
    notification_description: 'בחרנו עבורך את הפריטים המובילים וההמלצות המשתלמות ביותר ❤️',
    notification_button: 'נהדר!',
  },
  en: {
    notification_header: 'Thanks for choosing our digital recommendations platform!',
    notification_description: ' We picked here our best items and promotions for you ❤️',
    notification_button: 'Great!',
  },
}

export default translations
