import React, { useState } from 'react'
import { Box, Flex, Text } from '@chakra-ui/core'
import { ProductProps } from '../../config/interfaces'
import { isSolid } from '../../utils'

const CardBottom = ({ likes, className }: ProductProps) => {
  const [isLiked, setIsLiked] = useState(false)
  return (
    <Box className={className} overflow="hidden">
      <Flex style={{ direction: 'ltr' }} justifyContent="space-between" alignItems="center">
        <Box p={3} cursor="pointer" id={`like-${className}`} onClick={() => setIsLiked(!isLiked)}>
          <svg id={`like-${className}`} width="20px" height="17px" viewBox="0 0 20 17" version="1.1">
            <g id="Page-1" stroke="none" strokeWidth="1" fill={isLiked ? 'red' : 'none'} fillRule="evenodd">
              <g
                id="Artboard"
                transform="translate(-162.000000, -903.000000)"
                fillRule="nonzero"
                stroke={isLiked ? 'red' : '#616770'}
              >
                <path
                  d="M179.620969,905.409095 C177.78226,903.530302 174.80126,903.530302 172.96234,905.409095 L172,906.392416 L171.03766,905.409095 C169.198951,903.530302 166.21774,903.530302 164.379031,905.409095 C162.540323,907.287889 162.540323,910.334094 164.379031,912.212888 L165.341372,913.196208 L172,920 L178.658628,913.196208 L178.658628,913.196208 L179.620969,912.212888 C181.459677,910.334094 181.459677,907.287889 179.620969,905.409095 Z"
                  id="get-it"
                />
              </g>
            </g>
          </svg>
        </Box>

        {isSolid(likes) && (
          <Flex p={3}>
            <Text fontSize={13} color="gray.900">
              {likes} likes
            </Text>
          </Flex>
        )}
      </Flex>
    </Box>
  )
}

CardBottom.defaultProps = {
  colors: ['red', 'blue.400', 'green.300'],
}

export default CardBottom
