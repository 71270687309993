import React from 'react'
import { Box } from '@chakra-ui/core'
import { isSolid } from '../../utils'

interface LogoProps {
  image?: string
}

const Logo = ({ image }: LogoProps) => {
  return (
    <Box px="10px">{isSolid(image) && <img style={{ maxWidth: '100px' }} width="150" src={image} alt="logo" />}</Box>
  )
}

export default Logo
