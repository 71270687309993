import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Box, Button, Text } from '@chakra-ui/core'
import styled from 'styled-components'
import { isSolid } from '../../utils'
import { filterProductsByDepartment } from '../../redux/actions/general'

interface MenuProps {
  icon?: string
  isOpen?: boolean
  items?: any[]
  menuTextColor?: string
  isActive?: boolean
  filterProductsByDepartment?: any
  defaultDepartment?: string
  allDepartmentsText?: string
}

const OverLay = styled(Box)<MenuProps>`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1400;
  background: black;
  transition: all 350ms ease-in-out;
  opacity: ${props => (props.isOpen ? '0.8' : '0')};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  display: none;
`

const MenuList = styled(Box)<MenuProps>`
  border-top: 2px solid white;
  position: absolute;
  top: 62px;
  left: 0;
  width: 100%;
  z-index: 1500;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  max-height: ${props => (props.isOpen ? '300px' : '0')};
  opacity: ${props => (props.isOpen ? '1' : '0')};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
`
const MenuListItem = styled(Box)<MenuProps>`
  cursor: pointer;
  transition: opacity 350ms ease-in-out;
  font-weight: ${props => (props.isActive ? 'bold' : 400)};
`

const Menu = ({
  items,
  menuTextColor,
  filterProductsByDepartment,
  defaultDepartment,
  allDepartmentsText,
}: MenuProps) => {
  const [isMenuOpen, toggleMenu] = useState(false)
  const [activeMenu, setActiveMenu] = useState(isSolid(defaultDepartment) ? defaultDepartment : allDepartmentsText)
  const handleMenuItem = (item: any) => {
    filterProductsByDepartment(item)
    setActiveMenu(item)
    toggleMenu(!isMenuOpen)
  }
  return (
    <Box ml="12px" mr="12px" color="white">
      <OverLay isOpen={isMenuOpen} onClick={() => toggleMenu(!isMenuOpen)} />

      <Button
        className="filter"
        letterSpacing="0.5px"
        height={33}
        fontWeight={300}
        fontSize={14}
        borderRadius={16}
        borderColor={menuTextColor}
        color={menuTextColor}
        variantColor="white"
        variant="outline"
        textTransform="capitalize"
        style={{ direction: 'ltr' }}
        onClick={() => toggleMenu(!isMenuOpen)}
        leftIcon={isMenuOpen ? 'chevron-up' : 'chevron-down'}
      >
        {activeMenu === 'all' ? allDepartmentsText : activeMenu}
      </Button>
      <MenuList isOpen={isMenuOpen} textAlign="center">
        <MenuListItem
          className="filter-all"
          isActive={activeMenu === 'all'}
          bg="brand"
          py={15}
          onClick={() => handleMenuItem('all')}
        >
          <Text className="filter-all" fontSize={24} color={menuTextColor}>
            {allDepartmentsText}
          </Text>
        </MenuListItem>
        {isSolid(items) &&
          items!.map((item: any, index: number) => (
            <MenuListItem
              className={`filter-${item.title}`}
              isActive={activeMenu === item.title}
              key={index}
              bg="brand"
              py={15}
              textTransform="capitalize"
              onClick={() => handleMenuItem(item.title)}
            >
              <Text className={`filter-${item.title}`} fontSize={24} color={menuTextColor}>
                {item.title}
              </Text>
            </MenuListItem>
          ))}
      </MenuList>
    </Box>
  )
}

const dispatchPropsToState = {
  filterProductsByDepartment,
}

const mapStateToProps = ({ general }: any) => ({
  allDepartmentsText: general.config.texts.all_departments_text,
})

export default connect(mapStateToProps, dispatchPropsToState)(Menu)
