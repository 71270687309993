import React from 'react'
import { Box, Flex, Heading } from '@chakra-ui/core'
import { Toolbar, Card, BestSellers, Footer, Notify } from './components'
import { connect } from 'react-redux'
import { ProductProps } from './config/interfaces'
import { isSolid } from './utils'

interface KitProps {
  title?: string
  products?: ProductProps[]
  rollText?: string
  postText?: string
  associateText?: string
  config?: {
    pop: object
  }
}
const Kit = ({ config, products, rollText, postText, associateText }: KitProps) => {
  return (
    <Flex className="kit" flexDirection="column" minHeight="100vh">
      <Notify />

      <Toolbar />
      <Box as="main" flex="1">
        {isSolid(products) && (
          <>
            <BestSellers title={rollText} subtitle="עבור 30 יום" />
            <Heading
              textAlign="center"
              fontSize={19}
              textTransform="uppercase"
              color="gray.900"
              as="h3"
              size="lg"
              p={3}
            >
              {postText}
            </Heading>
            <Flex className="cards-list" flexWrap="wrap">
              {products!.map((product: ProductProps, index: number) => (
                <Card
                  index={index}
                  id={`post-${index + 1}`}
                  className={`post-${index + 1}`}
                  key={index}
                  image={product.image}
                  title={product.title}
                  price={product.price}
                  badge={product.badge}
                  likes={product.likes}
                  barcode={product.barcode}
                  type={product.type}
                  link={product.link}
                  associateText={associateText}
                  description={product.description}
                />
              ))}
            </Flex>
          </>
        )}
      </Box>

      <Footer />
    </Flex>
  )
}

const mapStateToProps = ({ general }: any) => ({
  config: general.config,
  products: general.products.section2,
  rollText: general.config.texts.roll_text,
  postText: general.config.texts.post_text,
  associateText: general.config.texts.associate_text,
})

export default connect(mapStateToProps)(Kit)
