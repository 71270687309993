import React from 'react'
import { Badge, Text } from '@chakra-ui/core'
import { ProductProps } from '../../config/interfaces'

const CardBadge = ({ badge, className }: ProductProps) => {
  return (
    <Badge className={className} bg="red" position="absolute" right={0} mt="20px" borderRadius="10px 0 0 10px">
      <Text fontSize={14} color="white" px={3} py="2px">
        {badge}
      </Text>
    </Badge>
  )
}

CardBadge.defaultProps = {
  badge: 'Hot Trend',
}
export default CardBadge
