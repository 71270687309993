import React, { useState } from 'react'
import { Box, Text, Flex, DrawerBody, useDisclosure } from '@chakra-ui/core'
import styled from 'styled-components'
import { useWindowSize } from '../../utils/hooks'
import { Image, Modal, InstaStories } from '../../components'
import { ProductProps } from '../../config/interfaces'
import { isSolid } from '../../utils'

const CardTitleWrap = styled(Flex)`
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CardBottom = styled(Box)`
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
`

const CardTitle = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
  font-size: 10px;
`

const Badge = styled(Box)`
  font-size: 12px;
  color: #333333;
  background: #e5e5e9;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  margin: 5px;
  width: 18px;
  height: 18px;
  text-align: center;
`

interface CarouselCardProps extends ProductProps {
  idx?: any
}

const Card = ({ title, price, idx, image }: CarouselCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isPortrait, setIsPortrait] = useState(false)

  const onImgLoad = ({ target }: any) => {
    const isPortrait = target.offsetHeight > target.offsetWidth
    setIsPortrait(isPortrait)
  }

  const size: any = useWindowSize()
  return (
    <Box
      style={{
        width: size.width / 3.7,
      }}
      bg="white"
      flex="0 0 auto"
      ml="6px"
      position="relative"
      overflow="hidden"
      borderRadius="8px"
      border="1px solid #E5E5E9"
      cursor="pointer"
      id={`roll-${idx + 1}`}
    >
      <Badge className={`roll-${idx + 1}`}>
        <span style={{ fontSize: 10 }} />
        {idx + 1}{' '}
      </Badge>

      <Flex
        onClick={onOpen}
        className={`roll-${idx + 1}`}
        style={{ height: '35vw' }}
        flexDirection="column"
        justifyContent={isPortrait ? 'start' : 'center'}
      >
        <Image
          onLoad={onImgLoad}
          className={`roll-${idx + 1}`}
          style={{
            width: '100%',
            objectFit: isPortrait ? 'cover' : 'contain',
          }}
          src={image!}
        />
      </Flex>

      <CardBottom className={`card-bottom roll-${idx + 1}`} position="absolute" bottom="0" pt="15px" width="100%">
        {isSolid(title) && (
          <CardTitleWrap className={`roll-${idx + 1}`} color="black" textAlign="center" fontSize={12} fontWeight={500}>
            <CardTitle>{title}</CardTitle>
          </CardTitleWrap>
        )}
        {isSolid(price) && (
          <CardTitleWrap className={`roll-${idx + 1}`} color="black" textAlign="center" fontSize={12} fontWeight={500}>
            <CardTitle>{price}</CardTitle>
          </CardTitleWrap>
        )}
      </CardBottom>

      <Modal isOpen={isOpen} onClose={onClose} isFull ModalContentBg="#111">
        <DrawerBody color="white" p={0}>
          <InstaStories closeStory={onClose} storyIndex={idx} />
        </DrawerBody>
      </Modal>
    </Box>
  )
}

Card.defaultProps = {
  image:
    'https://images.asos-media.com/products/lavish-alice-structured-one-shoulder-midi-dress-in-gold-sequin/13435500-1-gold?$XXL$&wid=513&fit=constrain',
}

export default Card
