import React from 'react'
import { Flex } from '@chakra-ui/core'
import Logo from './Logo'
import Menu from './Menu'
import { connect } from 'react-redux'
import { isSolid, get } from '../../utils'

interface ToolbarProps {
  departments?: string[]
  logo?: string
  menuTextColor?: any
  defaultDepartment?: any
}

const Toolbar = ({ departments, logo, menuTextColor, defaultDepartment }: ToolbarProps) => {
  return (
    <Flex
      color={menuTextColor}
      bg="brand"
      justifyContent="space-between"
      alignItems="center"
      height={62}
      position="sticky"
      top="0"
      zIndex={1000}
    >
      <Logo image={logo} />
      {isSolid(departments) && (
        <Menu items={departments} menuTextColor={menuTextColor} defaultDepartment={defaultDepartment} />
      )}
    </Flex>
  )
}

const mapStateToProps = ({ general }: any) => ({
  departments: general.config.departments,
  logo: general.config.logo,
  brandColor: general.config.brand_color,
  menuTextColor: general.config.colors.brand_text_color,
  defaultDepartment: get(general.config, 'default_department', null),
})

export default connect(mapStateToProps)(Toolbar)
