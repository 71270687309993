import React from 'react'
import { connect } from 'react-redux'
import { Box, Flex, Image } from '@chakra-ui/core'
import Instagram from '../../assets/instagram.svg'
import Facebook from '../../assets/facebook.svg'
import { isSolid } from '../../utils'

interface FooterProps {
  social?: any
}

const Footer = ({ social }: FooterProps) => {
  return (
    <Flex
      className="footer"
      py={3}
      bg="white"
      justifyContent="space-between"
      alignItems="center"
      position="sticky"
      bottom="0"
    >
      {isSolid(social) && (
        <>
          <Box mx={3}>
            {isSolid(social.website) && (
              <Box
                className="social-website"
                as="button"
                color="black"
                bg="white"
                borderRadius="4px"
                border="1px solid"
                borderColor="black"
                fontSize="14px"
                fontWeight={500}
                p="5px 10px"
                style={{ direction: 'ltr' }}
              >
                <a className="social-website" href={social.website} target="_blank" rel="noopener noreferrer">
                  {social.website}
                </a>
              </Box>
            )}
          </Box>
          <Flex mx={3}>
            <a className="social-facebook" href={social.facebook} target="_blank" rel="noopener noreferrer">
              <Image className="social-facebook" mx={3} src={Facebook} />
            </a>

            <a className="social-instagram" href={social.instagram} target="_blank" rel="noopener noreferrer">
              <Image className="social-instagram" mx={3} src={Instagram} />
            </a>
          </Flex>
        </>
      )}
    </Flex>
  )
}

const mapStateToProps = ({ general }: any) => ({
  social: general.config.social,
})

export default connect(mapStateToProps)(Footer)
