/* eslint-disable */
import React, { useState, useEffect, useLayoutEffect } from 'react'

function useWindowSize() {
  const isClient = typeof window === 'object'
  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    // console.log(windowSize, setWindowSize)
    // if (!isClient) {
    // 	return false
    // }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

function useLockBodyScroll() {
  useLayoutEffect(() => {
    // console.log('mounted')
    // Get original value of body overflow
    const originalStyle: any = window.getComputedStyle(document.body).overflow
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden'
    // Re-enable scrolling when component unmounts
    //  return () => document.body.style.overflow = originalStyle;
    return () => {
      // console.log('unmount')
    }
  }) // Empty array ensures effect is only run on mount and unmount
}

export { useWindowSize, useLockBodyScroll }
