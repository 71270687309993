import React from 'react'
import { connect } from 'react-redux'
import { Box, Flex, Text, Image } from '@chakra-ui/core'
import { ProductProps } from '../../config/interfaces'
import { CardLogoLoader } from '../Loaders'
import { isSolid } from '../../utils'

const CardTop = ({ title, price, logo, className }: ProductProps) => {
  return (
    <Box className={className}>
      <Flex>
        <Flex width="64px" height="64px" p={3} flexDirection="column" justifyContent="center">
          {isSolid(logo) ? <Image src={logo} borderRadius="50%" /> : <CardLogoLoader />}
        </Flex>

        {(isSolid(title) || isSolid(price)) && (
          <Box py={3}>
            <Text color="black" fontSize={14} fontWeight={500}>
              {title}
            </Text>
            <Text color="gray.700" fontSize={12}>
              {price}
            </Text>
          </Box>
        )}
      </Flex>
    </Box>
  )
}

const mapStateToProps = ({ general }: any) => ({
  logo: general.config.avatar,
})
export default connect(mapStateToProps)(CardTop)
