import React from 'react'
import { Box, Flex, Text, DrawerHeader, DrawerBody } from '@chakra-ui/core'
import Barcode from 'react-barcode'
import { ProductProps } from '../../config/interfaces'
import { Image, Drawer } from '../../components'
import PersonIcon from '../../assets/person.svg'
import { isSolid } from '../../utils'

interface BarcodeDrawerProps extends ProductProps {
  isOpen: any
  onClose: any
}

const styles = {
  barcodeAndTitleBox: {
    paddingRight: 20,
  } as React.CSSProperties,
}

const BarcodeDrawer = ({
  title,
  description,
  price,
  image,
  isOpen,
  onClose,
  barcode,
  associateText,
}: BarcodeDrawerProps) => {
  const isProductDataExisting = isSolid(title) || isSolid(price) || isSolid(barcode)
  return (
    <Drawer isOpen={isOpen} onClose={onClose} isBottom>
      <DrawerHeader
        onClick={onClose}
        color="black"
        fontSize="24px"
        textAlign="center"
        borderBottomWidth="1px"
        borderColor="gray.500"
      >
        <Flex justifyContent="center" marginBottom="24px">
          <Box bg="#282827" borderRadius="6px" height="6px" width="72px" />
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <Box mx={2}>
            <Image src={PersonIcon} />
          </Box>
          <Text textTransform="uppercase" style={{ direction: 'rtl' }}>
            {associateText}
          </Text>
        </Flex>
      </DrawerHeader>
      <DrawerBody onClick={onClose} paddingY="22px">
        {isSolid(description) && (
          <Text fontSize={14} mb={5}>
            {description}
          </Text>
        )}
        <Flex flexWrap="wrap">
          <Box pr={0} width={isProductDataExisting ? '40%' : '100%'}>
            <Image
              style={{
                borderRadius: '6px',
                maxWidth: isProductDataExisting ? '100%' : 260,
                margin: 'auto',
              }}
              src={image!}
            />
          </Box>
          {isProductDataExisting && (
            <Box width="60%" style={styles.barcodeAndTitleBox}>
              <Text fontSize={14}>{title}</Text>
              <Text fontSize={14} color="gray.700">
                {price}
              </Text>
              <Box className="styled-barcode">{isSolid(barcode) && <Barcode value={barcode} />}</Box>
            </Box>
          )}
        </Flex>
      </DrawerBody>
    </Drawer>
  )
}

export default BarcodeDrawer
