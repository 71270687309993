import React from 'react'
import { Flex } from '@chakra-ui/core'
import Card from './Card'

interface CarouselProps {
  data?: ItemProps[]
}

interface ItemProps {
  title: string
  department?: string
  avatar?: string
}

const StoryCarousel = ({ data }: CarouselProps) => {
  return (
    <Flex className="carousel" p={3} pt={3} overflowX="auto" flexWrap="nowrap" style={{ direction: 'ltr' }}>
      {data &&
        data.map((item: ItemProps, idx: number) => (
          <Card key={idx} idx={idx} title={item.title} image={item.avatar} department={item.title} />
        ))}
    </Flex>
  )
}

export default StoryCarousel
